import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import mediaPlayedJSON from './media.json';
import ThridScreenComponent from './QuestionDetailPage';
import { sideBarImage } from '../../../Constant/api';
export default function SecondScreenComponent(props) {
  const {
    QuestionsEditKey, setQuestionsEditKey,
    QuestionsEditData, setQuestionsEditData,
    QuestionsData, setQuestionsData,
    QuestionsEditMode, setQuestionsEditMode,
    TimingStarted, setTimingStarted,
    ExamData, AnswerArray,
    setChoose,
    SubmitExam, mediaPlayedJSON,
    PlaySingle
  } = props;
  const [Timing, setTiming] = React.useState(ExamData.meta.exam_time);
  const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(true);
  const [QuestionsEditMode2, setQuestionsEditMode2] = React.useState(true);
  const [TimerValue, setTimerValue] = React.useState("");
  const [ArrayIndex, setArrayIndex] = React.useState("");
  const [ArrayIndex2, setArrayIndex2] = React.useState(1);
  const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
  const [mediaPlayed, setMedaiPlayed] = React.useState(mediaPlayedJSON)
  const [isPlayingMedia, setIsPlayingMedia] = React.useState(false)
  const [submitExamPressed, setSubmitExamPressed] = React.useState(false)
  const LoginUser = useSelector((state) => state.LoginState);

  useEffect(() => {
    const date = new Date();
    const dateEnds = date.getTime() + Number(Timing) * 60 * 1000
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      if (TimingStarted && Timing !== "") {
        const datenow = new Date();
        const difference = parseInt((dateEnds - datenow.getTime()) / 1000);
        if (difference > 0) {
          const hoursValue = parseInt(difference / 60 / 60 % 24) < 10 ? `0${parseInt(difference / 60 / 60 % 24)}` : parseInt(difference / 60 / 60 % 24)
          const min = parseInt(difference / 60 % 60) < 10 ? `0${parseInt(difference / 60 % 60)}` : parseInt(difference / 60 % 60)
          const seconds = parseInt(difference % 60) < 10 ? `0${parseInt(difference % 60)}` : parseInt(difference % 60)
          setTimerValue(`${hoursValue} : ${min} : ${seconds}`)
        }
        else {
          setTimerValue("")
          SubmitExam()
        }
      }
    }, 1000)
    return () => clearInterval(intervalId); //This is important
  }, [])



  return (
    <div>
      {/* <div className="row " style={{ backgroundColor: "#003068", color: "#fff", padding: "10px", margin: "0px 0", }}>
        <div className="col-md-6 question-solved">
          Questions Solved: {QuestionsSolved}
        </div>
        <div className="col-md-6 question-solved">
          Time Remaining: {TimerValue}
        </div>
      </div> */}
      <div className="question-background">
        {QuestionsEditMode ?
          <div
          >
            <div className="row">
              <div className="center" style={{ padding: "10px", width: "10%" }}>
                <img src={sideBarImage}
                  style={{ width: "60%" }}
                />
              </div>
              <div style={{ width: "88.5%" }}>
                <div className="row title-padding" style={{ border: "1px solid #999", borderTopLeftRadius: 20 }}>
                  <div className="col-sm-4  col-6 center que-title-view">{ExamData.title.rendered}</div>
                  <div className="d-sm-block d-none col-4 center que-title-view">{ExamData.id}</div>
                  <div className="d-sm-block d-none col-4 center que-title-view">{LoginUser.userName}</div>
                  <div className="d-sm-none d-block col-sm-4 col-6 center que-title-view">{TimerValue}</div>
                </div>
                <div className=''>
                  <div className="row title-padding" style={{ border: "1px solid #999", borderBottomLeftRadius: 20 }}>
                    <div style={{ width: "20%" }} className="center que-title-view-2">Nepal</div>
                    <div style={{ width: "20%" }} className="center que-title-view-2">ALL</div>
                    <div style={{ width: "20%" }} className="center que-title-view-2">SOLVED</div>
                    <div style={{ width: "20%" }} className="center que-title-view-2">UNSOLVED</div>
                    <div style={{ width: "20%" }} className="center que-title-view-2">{TimerValue}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "5px" }}>
              <div style={{ width: "49%", marginRight: "1%" }}>
                <div className="title-padding" style={{ border: "1px solid #bbb", textAlign: "center", fontSize: "16px", borderRadius: "10px" }}>
                  Reading
                </div>
              </div>
              <div style={{ width: "49%", marginLeft: "1%" }}>
                <div className="title-padding" style={{ border: "1px solid #bbb", textAlign: "center", fontSize: "16px", borderRadius: "10px" }}>
                  Listening
                </div>
              </div>
            </div>
            {/* <div style={{ padding: "20px", }}>
              <span style={{
                border: "2px solid black",
                margin: "10px", width: "20px", height: "20px", backgroundColor: "#003068"
              }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp;Solved
              <span style={{
                border: "2px solid black",
                margin: "10px", width: "20px", height: "20px", backgroundColor: "#fff"
              }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp;Unsolved
            </div> */}
            <div className="row center">
              <div className="col-6 row center" >
                {Object.keys(QuestionsData).map((item, index) => {
                  if (index < 20)
                    return (
                      <div key={index}
                        className="col-2 m-1 question-number-font center"
                        style={{
                          backgroundColor: !AnswerArray[index].solved == 1 ? "#fff" : "#003068",
                          fontWeight: "bold",
                          border: "2px solid #999",
                          padding: "5px 0",
                          borderRadius: 5,
                          cursor: 'pointer',
                          color: !AnswerArray[index].solved == 1 ? "#000" : "#fff",
                        }}
                        onClick={() => {
                          if (index % 2 == 0) {
                            setQuestionsEditMode1(true)
                            setQuestionsEditMode2(false)
                          }
                          else {
                            setQuestionsEditMode2(true)
                            setQuestionsEditMode1(false)
                          }
                          setQuestionsEditData(QuestionsData[item])
                          setQuestionsEditKey(item)
                          setArrayIndex(index)
                          setArrayIndex2(index+1)
                        
                          setQuestionsEditMode(false)
                        }}
                      >
                        {QuestionsData[item].question_number}
                      </div>
                    )
                })}
              </div>
              <div className="col-6 row">
                {Object.keys(QuestionsData).map((item, index) => {
                  if (index >= 20)
                    return (
                      <div key={index} className="col-2 m-1  question-number-font center"
                        style={{
                          backgroundColor: !AnswerArray[index].solved == 1 ? "#fff" : "#003068",
                          fontWeight: "bold",
                          // border: !AnswerArray[index].solved == 1 ? "2px solid black" : "2px solid #156cf2",
                          border: "2px solid #999",
                          padding: "5px 0",
                          borderRadius: 5,
                          cursor: 'pointer',
                          color: !AnswerArray[index].solved == 1 ? "#000" : "#fff",
                        }}
                        onClick={() => {
                          if (index % 2 == 0) {
                            setQuestionsEditMode1(true)
                            setQuestionsEditMode2(false)
                          }
                          else {
                            setQuestionsEditMode2(true)
                            setQuestionsEditMode1(false)
                          }
                          setQuestionsEditData(QuestionsData[item])
                          setQuestionsEditKey(item)
                          setArrayIndex(index)
                          setArrayIndex2(index+1)
                         
                          setQuestionsEditMode(false)
                        }}
                      >
                        {QuestionsData[item].question_number}
                      </div>
                    )
                })}
              </div>
              <div className="row">
                <div className="col-lg-4 col-12" />
                <div className="col-lg-4 col-12 center">
                  <button className="sign-in-button-11 col-lg-8 col-7"
                    onClick={() => {
                      setSubmitExamPressed(true)
                    }}
                  >
                    Submit and Finish Exam
                  </button>
                </div>
              </div>
            </div>
            {submitExamPressed &&
              <section className="modal image-zoom-modal" >
                <div className="row">
                  <div className="col-md-4 col-1"></div>
                  <div className="col-md-4 col-10  shadow bg-white">
                    <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                      <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                        onClick={() => {
                          setSubmitExamPressed(false)

                        }}
                      >X</div>
                      Do you really want to finish the exam?<br />
                      Finishing exam is irreversible.
                      <div className="row" style={{ overflow: "hidden" }}>
                        <div className="col-1" />
                        <div className="col-5 center " >
                          <div className="sign-in-button-4" style={{ width: "80%" }}
                            onClick={() => {
                              setSubmitExamPressed(false)

                            }}
                          >
                            Cancel
                          </div>
                        </div>
                        <div className="col-5 center">
                          <div className="sign-in-button-4" style={{ width: "80%" }}
                            onClick={() => {
                              setChoose(2)
                              SubmitExam()
                              setSubmitExamPressed(false)
                            }}
                          >
                            Submit and Finish
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            }
          </div>
          :
          <div>
            <div style={{ width: "100%", marginLeft: "15px", textAlign: "center" }} className="center">
              <div className=' d-sm-block d-none '>
                <div className="row title-padding" style={{ borderBottom: "1px solid #000", width: "100%" }}>
                  <div style={{ width: "22%" }} className="center que-title-view-2">
                    {QuestionsEditData.question_number < 20 ? "Reading" : "Listening"} ({AnswerArray.length / 2} que)
                  </div>
                  <div style={{ width: "25%" }} className="center que-title-view-2">Total ({AnswerArray.length})</div>
                  <div style={{ width: "25%" }} className="center que-title-view-2">Remaining ({AnswerArray.filter(a => a.solved == 0).length})</div>
                  <div style={{ width: "22%" }} className="center que-title-view-2">{TimerValue}</div>
                </div>
              </div>
              <div className=' d-sm-none d-block '>
                <div className="row title-padding" style={{ borderBottom: "1px solid #000", width: "100%" }}>
                  <div style={{ width: "50%" }} className="center que-title-view-2">
                    {QuestionsEditData.question_number < 20 ? "Reading" : "Listening"} ({AnswerArray.length / 2} que)
                  </div>
                  <div style={{ width: "50%" }} className="center que-title-view-2">{TimerValue}</div>
                </div>
              </div>
            </div>
            {!QuestionsEditMode1 && ArrayIndex2 % 2 == 0 ?
              <ThridScreenComponent
                keyValue={QuestionsEditKey}
                oneQuestionData={QuestionsEditData}
                AllQuestionData={QuestionsData}
                setAllQuestionData={setQuestionsData}
                AnswerArray={AnswerArray}
                ArrayIndex={ArrayIndex}
                setQuestionsSolved={setQuestionsSolved}
                QuestionsSolved={QuestionsSolved}
                mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
                setIsPlayingMedia={setIsPlayingMedia}
                isPlayingMedia={isPlayingMedia}
                PlaySingle={PlaySingle}

              />
              :
              <div></div>
            }
            {!QuestionsEditMode2&& ArrayIndex2 % 2 == 1 ?
              <ThridScreenComponent
                keyValue={QuestionsEditKey}
                oneQuestionData={QuestionsEditData}
                AllQuestionData={QuestionsData}
                setAllQuestionData={setQuestionsData}
                AnswerArray={AnswerArray}
                ArrayIndex={ArrayIndex}
                setQuestionsSolved={setQuestionsSolved}
                QuestionsSolved={QuestionsSolved}
                mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
                setIsPlayingMedia={setIsPlayingMedia}
                isPlayingMedia={isPlayingMedia}
                PlaySingle={PlaySingle}
              />
              :
              <div></div>
            }
            <div className="row mx-5" style={{ backgroundColor: "#fff" }} >
              <div className="col-4">
                {ArrayIndex !== 0 &&
                  <div className="col-12 previous-question" style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (ArrayIndex !== 0) {
                        setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
                        setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
                        setArrayIndex(ArrayIndex - 1)
                        setArrayIndex2(ArrayIndex2 - 1)
                        if (!isPlayingMedia) {
                          // setQuestionsEditMode1(true)
                          // setTimeout(() => {
                          //   setQuestionsEditMode1(false)
                          // }, 10)
                          if (ArrayIndex2 % 2 == 0) {
                            setQuestionsEditMode1(true)
                            setTimeout(() => {
                              setQuestionsEditMode2(false)
                            }, 10)
                          }
                          else {
                            setQuestionsEditMode1(false)
                            setTimeout(() => {
                              setQuestionsEditMode2(true)
                            }, 10)
                          }

                        }
                      }
                    }}
                  >
                    <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                      <i className="fa fa-chevron-left" />&nbsp; Previous Question
                    </button>
                  </div>
                }
              </div>
              <div className="col-4 " style={{ textAlign: 'center', cursor: "pointer" }}
                onClick={() => {
                  if (!isPlayingMedia) {
                    setQuestionsEditMode(true)
                  }
                }}
              >
                <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                  <i className="fa fa-list" />&nbsp;   Question List
                </button>
              </div>
              {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
                <div className="col-4 question-list" style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isPlayingMedia) {
                      setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                      setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
                      setArrayIndex(ArrayIndex + 1)
                      setArrayIndex2(ArrayIndex2 + 1)
                      // if (ArrayIndex % 2 == 0) {

                      //   setQuestionsEditMode1(true)
                      //   setQuestionsEditMode2(false)
                      // }
                      // else {
                      //   setQuestionsEditMode1(false)
                      //   setQuestionsEditMode2(true)
                      // }
                      if (ArrayIndex2 % 2 == 0) {
                        setQuestionsEditMode1(true)
                        setTimeout(() => {
                          setQuestionsEditMode2(false)
                        }, 10)
                      }
                      else {
                        setQuestionsEditMode1(false)
                        setTimeout(() => {
                          setQuestionsEditMode2(true)
                        }, 10)
                      }
                      // setTimeout(() => {
                      //   setQuestionsEditMode1(false)
                      // }, 10)

                    }
                  }}
                >
                  <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                    Next Question&nbsp;  <i className="fa fa-chevron-right" />
                  </button>
                </div>
              }
            </div>



          </div>
        }
      </div>
    </div>
  )
}